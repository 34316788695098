/* entering */

.fadeslide-enter {
    opacity: 0;
    transform: translate(0, -40px);
}

.fadeslide-enter.fadeslide-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 500ms ease-out, opacity 500ms ease-in;
}


/* leaving */

.fadeslide-leave {
    opacity: 1;
    transform: translate(0, 0);
}

.fadeslide-leave.fadeslide-leave-active {
    opacity: 0;
    transform: translate(0, 40px);
    transition: transform 300ms ease-out, opacity 300ms ease-in;
}


/* first time entering */

.fadeslide-appear {
    opacity: 0;
    transform: translate(0, -40px);
}

.fadeslide-appear.fadeslide-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 500ms ease-out 500ms, opacity 500ms ease-in 500ms;
    /* last arg is default delay, first children have it shorter to stagger */
}

.fadeslide-appear.fadeslide-appear-active:nth-child(0),
.fadeslide-appear.fadeslide-enter-active:nth-child(0) {
    transition-delay: 0ms;
}

.fadeslide-appear.fadeslide-appear-active:nth-child(1),
.fadeslide-appear.fadeslide-enter-active:nth-child(1) {
    transition-delay: 100ms;
}

.fadeslide-appear.fadeslide-appear-active:nth-child(2),
.fadeslide-appear.fadeslide-enter-active:nth-child(2) {
    transition-delay: 200ms;
}

.fadeslide-appear.fadeslide-appear-active:nth-child(3),
.fadeslide-appear.fadeslide-enter-active:nth-child(3) {
    transition-delay: 300ms;
}

.fadeslide-appear.fadeslide-appear-active:nth-child(4),
.fadeslide-appear.fadeslide-enter-active:nth-child(4) {
    transition-delay: 400ms;
}