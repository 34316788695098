body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fecd1a;
    text-align: center;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #fee176;
}

::-webkit-scrollbar-thumb {
    background: #fff0ba;
}

::-webkit-scrollbar-thumb:hover {
    background: #fffae8;
}

@font-face {
    font-family: 'TrashHand';
    src: local('TrashHand'), url(./fonts/TrashHand.ttf) format('truetype');
}

.title-header>a {
    font-family: TrashHand;
    font-size: 9vw;
    /* text color */
    color: #120078;
    text-decoration: none;
}

.title-header {
    padding-top: 3.5vh;
    padding-bottom: 2vh;
}

@media only screen and (min-width: 768px) {
    /* For tablet: */
    .title-header>a {
        font-size: 6vw;
    }
}

@media only screen and (min-width: 1025px) {
    /* For monitor desktop: */
    .title-header>a {
        font-size: 3.6vw;
    }
}

.title-header>a:focus,
.title-header>a:hover,
.title-header>a:visited,
.title-header>a:link,
.title-header>a:active {
    text-decoration: none;
}

.main-content {
    margin: auto;
    width: 100%;
}

* {
    box-sizing: border-box;
}

[class*="col-"] {
    float: left;
    padding-bottom: 2.5px;
    padding-top: 2.5px;
    padding-left: 5px;
    padding-right: 5px;
}

[class*="col-"] {
    /* default, mobile first: */
    width: 100%;
}

@media only screen and (min-width: 768px) {
    /* For tablet: */
    .col-thumbnail {
        width: 50%;
        padding: 5px;
    }
    .main-content {
        width: 80%;
    }
}

@media only screen and (min-width: 1025px) {
    /* For monitor desktop: */
    .col-thumbnail {
        width: 50%;
    }
    .main-content {
        width: 60%;
    }
}

.thumbnail-grid-row::after {
    content: "";
    clear: both;
    display: table;
}