.buttons-bar {
    height: 10vw;
    line-height: 10vw;
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 768px) {
    /* For tablet: */
    .buttons-bar {
        height: 5vw;
        line-height: 5vw;
    }
}

@media only screen and (min-width: 1025px) {
    /* For monitor desktop: */
    .buttons-bar {
        height: 2.3vw;
        line-height: 1.15vw;
    }
}

.buttons-bar>img,
.buttons-bar>a>img {
    max-height: 100%;
    margin-bottom: 5px;
    margin-left: 15px;
    cursor: pointer;
    transition: transform .5s;
}

.buttons-bar>a {
    max-height: 100%;
}

.buttons-bar>img:hover,
.buttons-bar>img:active {
    transform: rotate(12deg);
}

.about-me-button {
    margin-right: 15px;
}

.video-filter-button,
.photo-filter-button {
    float: left;
}

.about-me-button {
    float: right;
}

.thumbnail-grid-row {
    margin-top: 10px;
}

.col-thumbnal>a,
.thumbnail-image {
    width: 100%;
}

.col-fullrow {
    padding-top: 5px;
    padding-bottom: 10px;
}

.thumbnail-image {
    float: left;
}

.nothing-to-see-text {
    font-size: 4vw;
}

@media only screen and (min-width: 768px) {
    /* For tablet: */
    .nothing-to-see-text {
        font-size: 1.2vw;
    }
}

.collection-title {
    display: inline;
    vertical-align: middle;
    font-size: 2.3vh;
    font-weight: 380;
}