.thumbnail-image {
    transition: .4s ease-out;
    /* backface-visibility: hidden; */
}

.thumbnail-overlay {
    transition: .5s ease-out;
    opacity: 0;
    position: relative;
}

.col-thumbnail:hover .thumbnail-image {
    filter: brightness(50%);
}

.col-thumbnail:hover .thumbnail-overlay {
    opacity: 1;
}

.thumbnail-overlay>img {
    position: absolute;
    left: 91%;
    width: 6%;
    transform: translate(0, 40%);
    -ms-transform: translate(0, 40%);
}