.viewed-media {
    margin: auto;
    width: 100%;
    padding: 5px;
}

@media only screen and (min-width: 1025px) {
    /* For monitor desktop: */
    .viewed-media {
        width: 70%;
    }
}

.viewed-media>img,
.viewed-media>video {
    max-width: 100%;
    max-height: 85vh;
}

.back-button-bar {
    display: none;
}

@media only screen and (min-width: 1025px) {
    /* For monitor desktop: */
    .back-button-bar {
        display: block;
    }
    .back-button-bar>a {
        float: left;
        max-height: inherit;
        height: inherit;
    }
    img.back-arrow-button {
        max-height: 100%;
        margin-top: 20px;
    }
}


/* .buttons-bar>a {
    
} */